<template>
  <v-lazy
    :min-height="avatarSize"
    :options="{ 'threshold': 0.1 }"
    transition="fade-transition"
  >
    <v-chip
      v-bind="$attrs"
      :color="ticketTag.color"
      :density="density ?? 'comfortable'"
      :to="disableClick ? undefined : { name: 'tickets', query: { tags: ticketTag._id, tab: 'LIST' } }"
    >
      <span>{{ ticketTag.description }}</span>
    </v-chip>
  </v-lazy>
</template>

<script setup lang="ts">
const props = defineProps<{
  ticketTag: Pick<TicketTag, "_id" | "color" | "description">
  disableClick?: boolean
  density?: "comfortable" | "compact" | "default"
}>()
const { ticketTag, disableClick, density } = toRefs(props)

const avatarSize = computed(() => {
  if (density?.value === "compact") return "1.3rem"
  if (density?.value === "comfortable" || !density.value) return "1.5rem"
  return "2rem"
})
</script>